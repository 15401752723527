"use client";

import classNames from "classnames/bind";
import { removeUtmParams } from "../../../helpers/removeUtmParams";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import {
  BlocksHighlightsStage,
  BlocksWorbRail,
} from "../../../__generated__/contentful-types";
import {
  ExplodedWordsToSpans,
  waitForScrollConfig,
} from "../interactive-hero/utils/interactive-hero-utils";
import { dataLayer } from "../../../helpers/dataLayer";
import { useEffect, useId, useLayoutEffect, useRef, useState } from "react";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { WorbRailItem } from "../../../__generated__/crepo-types";
import { useAnalyticsTrackingState } from "../../../helpers/useAnalyticsTrackingState";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import { WorbRailCard } from "./worb-rail-card";
import { SliderButton } from "../../slider-button/slider-button";
import useEmblaCarousel from "embla-carousel-react";
import { env } from "../../../helpers/env";
import styles from "./worb-rail.module.css";
import { useLocaleContext } from "../../../helpers/LocaleContext";

const ANALYTICS_MAXIMUM_CARDS = 3;
const cx = classNames.bind(styles);

export interface WorbRailProps {
  block: BlocksWorbRail | BlocksHighlightsStage;
  dotcomContent: WorbRailItem[];
  title?: string | null;
  animated?: boolean;
}

export const WorbRail = ({
  block,
  dotcomContent,
  title = "",
  animated,
}: WorbRailProps) => {
  const locale = useLocaleContext();
  const textDirection = useTextDirectionContext();

  const { ref, isIntersecting } = useObserveElementRef<HTMLDivElement>({
    threshold: 0,
  });

  const analyticsTrackingState = useAnalyticsTrackingState();

  useEffect(() => {
    if (
      env.NEXT_PUBLIC_HAS_GTM_CONTAINER &&
      isIntersecting &&
      dotcomContent.length > 0
    ) {
      const cards = dotcomContent.slice(0, ANALYTICS_MAXIMUM_CARDS);

      if (
        !analyticsTrackingState?.some(
          (item) =>
            item.id === cards.map((card) => card.id).join(", ") &&
            item.event === "moduleImpression",
        )
      ) {
        analyticsTrackingState.push({
          id: cards.map((card) => card.id).join(", "),
          event: "moduleImpression",
        });

        analyticsImpression({
          activeCards: cards,
          cards: dotcomContent,
          locale: locale,
        });
      }
    }
  }, [isIntersecting, dotcomContent, analyticsTrackingState, locale]);

  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!animated) {
      return;
    }
    const ctx = gsap.context(() => {
      const rootElement = ref.current;

      const titleWordsElements = titleRef.current?.querySelectorAll("span");
      const contentElement = contentRef.current;
      const timeline = gsap.timeline();
      timeline.from(titleWordsElements ?? null, {
        duration: 0.4,
        y: "+=60%",
        opacity: 0,
        stagger: 0.1,
        clearProps: "all",
      });
      timeline.from(
        contentElement ?? null,
        { duration: 0.6, y: "+=100", opacity: 0 },
        0.5,
      );
      timeline.pause();
      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 70%",
        animation: timeline,
        once: true,
      });
      scrollTrigger.disable();
      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });

    return () => ctx.revert();
  }, [animated, ref]);

  const [mainCarouselRef, mainCarousel] = useEmblaCarousel({
    align: "start",
    loop: false,
    containScroll: "keepSnaps",
    draggable: true,
    direction: textDirection,
  });

  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(true);

  useEffect(() => {
    const evaluatePrevAndNextButtons = () => {
      setIsNextDisabled(!mainCarousel?.canScrollNext());
      setIsPrevDisabled(!mainCarousel?.canScrollPrev());
    };

    mainCarousel?.on("select", evaluatePrevAndNextButtons);

    evaluatePrevAndNextButtons();

    return () => {
      mainCarousel?.off("select", evaluatePrevAndNextButtons);
    };
  }, [mainCarousel]);

  const titleId = useId();

  const { ref: intersectionRef, hasIntersected } =
    useObserveElementRef<HTMLDivElement>({
      threshold: 0,
    });
  /**
   * You might ask - isn't this redundant? Isn't this the whole point of the
   * "loading=lazy" attribute?
   *
   * The issue is carousels might be scrolled *vertically* into the viewport,
   * but images that are *horizontally* positioned offscreen will still not
   * load until they're moved into view by interacting with the carousel.
   */
  const imageLoading = hasIntersected ? "eager" : "lazy";

  return (
    <div
      className={cx("container", {
        "container--hidden": !dotcomContent.length,
      })}
      ref={ref}
      role="group"
      aria-labelledby={titleId}
      aria-roledescription="carousel"
    >
      <div className={cx("inner")} ref={intersectionRef}>
        <div className={cx("main")}>
          <div className={cx("header")}>
            <h2 id={titleId} ref={titleRef} className={cx("title")}>
              {animated ? (
                <ExplodedWordsToSpans text={title || block.title} />
              ) : (
                title || block.title
              )}
            </h2>
            <div
              className={cx("slider-button-wrapper")}
              role="group"
              aria-label="Slide controls"
            >
              <SliderButton
                onClick={() => mainCarousel?.scrollPrev()}
                kind="previous"
                disabled={isPrevDisabled ? true : undefined}
                className={cx("slider-button")}
              />
              <SliderButton
                onClick={() => mainCarousel?.scrollNext()}
                kind="next"
                disabled={isNextDisabled ? true : undefined}
                className={cx("slider-button")}
              />
            </div>
          </div>
          <div className={cx("viewport")}>
            <div className={cx("inner")}>
              <div className={cx("rail")} ref={mainCarouselRef}>
                <div ref={contentRef} className={cx("cards")}>
                  {dotcomContent.map((card, index, originalArray) => (
                    <div
                      key={index}
                      className={cx("card")}
                      role="group"
                      aria-label={`Slide ${index + 1} of ${
                        originalArray.length
                      }`}
                    >
                      <WorbRailCard card={card} loading={imageLoading} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getCardLink(
  data: WorbRailItem,
  locale: string,
  section?: string,
): string {
  if (data.url && data.url.href) {
    return data.url.href;
  }
  return data.uriSlug
    ? `https://www.redbull.com/${locale}/${section ? `${section}/` : ""}${
        data.uriSlug
      }`
    : "#";
}

function analyticsImpression({
  activeCards,
  cards,
  locale,
}: {
  activeCards: WorbRailItem[];
  cards: WorbRailItem[];
  locale: string;
}) {
  const itemsAsPromotions = activeCards.map((activeCard) => {
    let cardLink = "";
    let cardType = "";

    switch (activeCard.type) {
      case "rrn:content:stories":
        cardLink = getCardLink(activeCard, locale);
        cardType = "Stories";
        break;
      case "rrn:content:externals":
      case "rrn:content:project-profiles":
      case "rrn:content:event-profiles":
        cardLink = getCardLink(activeCard, locale, "projects");
        cardType = "Externals";
        break;
      case "rrn:content:person-profiles":
      case "rrn:content:team-profiles":
        cardLink = getCardLink(activeCard, locale, "athlete");
        cardType = "Video";
        break;
      case "rrn:content:videos":
      case "rrn:content:videos-360":
        cardLink = getCardLink(activeCard, locale, "athlete");
        cardType = "Video";
        break;
      case "rrn:content:films":
      case "rrn:content:movies":
        cardLink = getCardLink(activeCard, locale, "athlete");
        cardType = "Stories";
        break;
      default:
        cardLink = "";
        cardType = "";
    }

    return {
      id: activeCard.id,
      name: removeUtmParams(cardLink),
      creative: cardType,
      position: cards.findIndex((card) => card.id === activeCard.id) + 1,
    };
  });

  return dataLayer.push({
    event: "moduleImpression",
    availableElements: cards.length,
    promotionmodule: "feed-cards-rail-panel_custom_detachedarrownav",
    ecommerce: {
      promoView: {
        promotions: itemsAsPromotions,
      },
    },
  });
}
